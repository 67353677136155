import {
  userLoginSuccess,
  exceptionError,
  loadingStart,
  getUserDetailsSuccess,
  exceptionAPIError,
  postUserDetailsSuccess,
  exceptionSessionExpire,
  getMenuItemsSuccess,
  getUsersSuccess,
  postAdminRoleAssignmentDetailsSuccess,
  getGdriveDetailsSuccess,
  postGdriveDetailsSuccess,
  postGdriveserviceSuccess,
  userUpdatePasswordSuccess,
  userForgotPasswordSuccess,
} from "../actions";
import { put } from "redux-saga/effects";
import apisauce from "apisauce";
import Cookies from "js-cookie";
import { getApiEndpoint } from "../apiConfig";
export const url = getApiEndpoint();

// export const url = "http://excelrate:8000/";
Cookies.set("ABC", "ABC");
const api = apisauce.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 600000,
});
const navMonitor = (response) => {};
api.addMonitor(navMonitor);

export function* userLoginSaga(actions) {
  yield put(loadingStart());
  var data = JSON.stringify({
    usr: actions.username,
    pwd: actions.password,
    isSuperAdmin: actions.isSuperAdmin
  });
  try {
    const response = yield api.post("api/method/login", data);
    yield put(userLoginSuccess(response, actions.submitted));
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getUserDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
    };
    const response = yield api.get(
      "api/method/excelrate.adminApi.user_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getUserDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postUserDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      action: actions.action,
      isactive: actions.is_active,
      data: actions.data,
    };
    const response = yield api.post(
      "api/method/excelrate.adminApi.user_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postUserDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* getMenuItemsSaga() {
  yield put(loadingStart());
  try {
    var data = {};
    const response = yield api.get(
      "api/method/excelrate.adminApi.GetMenuList",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getMenuItemsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getUsersSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {};
    const response = yield api.get(
      "api/method/excelrate.adminApi.get_users",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getUsersSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postAdminRoleAssignmentDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      data: actions.data,
    };
    const response = yield api.post(
      "api/method/excelrate.adminApi.admin_role_assignment",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postAdminRoleAssignmentDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getGdriveDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {};
    const response = yield api.get(
      "api/method/excelrate.adminApi.gdrive_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getGdriveDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postGdriveDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      data: actions.data,
    };
    const response = yield api.post(
      "api/method/excelrate.adminApi.gdrive_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postGdriveDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postGdriveServiceSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      data: actions.data,
    };
    const response = yield api.post(
      "api/method/excelrate.adminApi.gdrive_service",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postGdriveserviceSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* userUpdatePasswordSaga(actions) {
  yield put(loadingStart());
  var data = {
    new_password: actions.new_password,
    key: actions.key,
  };
  try {
    const response = yield api.post(
      "api/method/frappe.core.doctype.user.user.update_password",
      data
    );
    yield put(userUpdatePasswordSuccess(response));
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* userForgotPasswordSaga(actions) {
  yield put(loadingStart());
  var data = {
    email: actions.email,
    domain: actions.domain,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.common.forgot_password",
      data
    );
    yield put(userForgotPasswordSuccess(response));
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

