import { React, useEffect, useRef, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import "./HomeComponent.css";
import LogoutIcon from "../assets/images/logout.png";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import ExcelRateLogo from "../assets/images/exclrate_logo.png";
import { url } from "../store/sagas/API.js";
import { BsPersonFill } from "react-icons/bs";
import Loader from "./Loader.js";
import { useSelector } from "react-redux";

function HomeComponent({
  isOpen,
  toggle,
  menuItems,
  isHovered,
  hoveredItem,
  handleMouseEnter,
  handleMouseLeave,
  username,
  content,
}) {
  const [getUrl, setGetUrl] = useState(false);
  useEffect(() => {
    const currentURL = window.location.pathname;
    setGetUrl(currentURL);
    console.log("currentURL", currentURL);
  }, []);

  const showLoader = useSelector((state) => state.showLoading);
  const sidebarRef = useRef(null);
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <a href="/logout">Login Again</a>
      </div>
    );
  } else {
    return (
      <>
        {showLoader && <Loader />}

        <div className="container-fluid">
          <div
            className="row"
            style={{
              height: "100%",
              display: "flex",
              position: "absolute",
              overflow: "hidden",
              width: "100%",
            }}
          >
            <aside
              style={{
                width: isOpen ? "220px" : "50px",
                position: "sticky",
                left: 0,
                zIndex: 100,
              }}
              className="sidebar"
              ref={sidebarRef}
            >
              <div className="top_section">
                <img
                  alt=""
                  style={{ display: isOpen ? "block" : "none", width: "150px" }}
                  src={ExcelRateLogo}
                />
                <div
                  style={{ marginLeft: isOpen ? "20px" : "0px" }}
                  className="bars"
                >
                  {isOpen ? (
                    <BiChevronsLeft
                      style={{ cursor: "pointer" }}
                      onClick={toggle}
                    />
                  ) : (
                    <BiChevronsRight
                      style={{ cursor: "pointer" }}
                      onClick={toggle}
                    />
                  )}
                </div>
              </div>
              <div>
                <div className="row" style={{ paddingTop: 50 }}>
                  {menuItems &&
                    menuItems.length > 0 &&
                    menuItems.map((item, index) => (
                      <>
                        <NavLink
                          key={index}
                          to={item.url}
                          className="firstsidebar-item"
                          style={{
                            backgroundColor: getUrl === item.url ? "white" : "",
                          }}
                          id={item.id}
                        >
                          <div
                            className="firstsidebar-title"
                            onMouseEnter={() => handleMouseEnter(item.title)}
                            onMouseLeave={() => handleMouseLeave()}
                          >
                            <span>
                              {item.icon && (
                                <img alt="" src={url + item.icon} />
                              )}
                            </span>
                            <div className="link_text">
                              <span
                                style={{ display: isOpen ? "flex" : "none" }}
                              >
                                {item.title}
                              </span>
                            </div>
                            {isHovered &&
                              hoveredItem === item.title &&
                              !isOpen && (
                                <div
                                  className="tooltip"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {item.title}
                                </div>
                              )}
                          </div>
                        </NavLink>
                      </>
                    ))}
                  <div>
                    <div
                      className=""
                      style={{
                        bottom: 10,
                        position: "absolute",
                        minWidth: 50,
                      }}
                    >
                      <div className="firstsidebar-title">
                        <span
                          style={{
                            marginRight: isOpen ? "" : 10,
                            gap: 15,
                          }}
                        >
                          <BsPersonFill />
                        </span>
                        <div
                          style={{ display: isOpen ? "block" : "none" }}
                          className="link_text"
                        >
                          {username}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
            <div
              style={{
                width: isOpen ? "calc(100% - 220px" : "calc(100% - 50px)",
              }}
            >
              <div
                style={{
                  height: "60px",
                  borderBottom: "1px solid #ccc",
                  width: "100%",
                }}
              >
                <div style={{ lineHeight: "60px", float: "right" }}>
                  <a
                    href="/logout"
                    title="Logout"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <img
                      alt=""
                      style={{
                        marginLeft: 5,
                        padding: 7,
                        width: 35,
                        borderRadius: 35,
                        border: "1px solid #ccc",
                      }}
                      src={LogoutIcon}
                    />
                  </a>
                </div>
              </div>
              <div style={{ overflowY: "auto" }}>{content}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeComponent;
