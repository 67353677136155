export const getApiEndpoint = () => {
  const url = new URL(window.location.href);
  const protocol = url.protocol;
  const hostname = url.hostname;
  const port = url.port ? `:${url.port}` : "";
  const parts = hostname.split(".");
  let subdomain = parts[0];
  const domain = parts.slice(1).join(".");
  let apiEndpoint = "";
  if (subdomain !== "localhost") {
    if (subdomain.includes("clientadmin")) {
      const previousSubdomain = subdomain.split("clientadmin")[0];
      apiEndpoint = `${protocol}//${previousSubdomain}admin.${domain}${port}`;
    } else {
      apiEndpoint = `${protocol}//admin.${domain}${port}`;
    }
  } else {
    apiEndpoint = `${protocol}//excelrate:8000`;
  }
  return apiEndpoint;
};

export const AdminPortal = () => {
  const url = new URL(window.location.href);
  const protocol = url.protocol;
  const hostname = url.hostname;
  const port = url.port ? `:${url.port}` : "";
  const parts = hostname.split(".");
  const subdomain = parts[0];
  const domain = parts.slice(1).join(".");
  let apiAdminPortalurl = "";
  if (subdomain !== "localhost") {
    if (subdomain && subdomain.length > 0) {
      apiAdminPortalurl = `${protocol}//${subdomain}.${domain}${port}`;
    } else {
      apiAdminPortalurl = `${protocol}//clientadmin.${domain}${port}`;
    }
  } else {
    apiAdminPortalurl = `${protocol}//localhost${port}`;
  }
  return apiAdminPortalurl;
};
